import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { graphql, useStaticQuery } from "gatsby";

export function useBestSellingBundles() {
  const data = useStaticQuery(graphql`
    query BestSellingBundlesQuery {
      bestSellingBundles {
        value
      }
    }
  `);
  return data.bestSellingBundles.value as RouteData.BestSellingBundles;
}
