import Helmet from "react-helmet";
import join from "lodash/join";
import map from "lodash/map";
import React, { useEffect } from "react";
import { BestSellingBundles } from "components/BestSellingBundles";
import { CategoryFragment, FileFragment } from "@trunkery/internal/lib/vature-gen/types";
import { CategoryHighlight } from "components/CategoryHighlight";
import { Config, RouteData } from "@trunkery/internal/lib/vatureTypes";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { Layout } from "components/Layout";
import { LazyImage } from "components/LazyImage";
import { Link } from "gatsby";
import { RawContent } from "components/RawContent";
import { SaveBadge } from "components/SaveBadge";
import { VariantAnchor } from "components/VariantAnchor";
import { VariantPrice } from "components/VariantPrice";
import { analyticsProductData } from "@trunkery/internal/lib/analyticsProductData";
import { categoryChain } from "utils/categoryChain";
import { categoryLink } from "utils/categoryLink";
import { convertImageURL } from "@lana-commerce/core/convertImageURL";
import { defaultVariant } from "@lana-commerce/core/defaultVariant";
import { imageFallback } from "utils/imageFallback";
import { useBestSellingBundles } from "utils/useBestSellingBundles";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";
import { useSiteData } from "utils/useSiteData";
import { variantImages } from "@lana-commerce/core/variantImages";

const img350 = (cdn: string, f?: FileFragment) => convertImageURL(cdn, f?.public_url, { size: "350x350" });
const img600 = (cdn: string, f?: FileFragment) => convertImageURL(cdn, f?.public_url, { size: "600x600" });
const img200 = (cdn: string, f?: FileFragment) => convertImageURL(cdn, f?.public_url, { size: "200x200" });

const pimg = (cdn: string, f?: FileFragment, crossfade?: boolean) =>
  f ? (
    <LazyImage
      fallback={imageFallback(f)}
      src={img200(cdn, f)}
      srcSet={`${img350(cdn, f)} 350w,
             ${img600(cdn, f)} 600w`}
      sizes="(min-width: 1210px) calc((100vw - 450px) / 4), 100vw"
      className={crossfade ? "img img-crossfade" : "img"}
      alt={f?.image_alt}
      title={f?.image_alt}
    />
  ) : (
    <img
      src={img200(cdn, f)}
      srcSet={`${img350(cdn, f)} 350w,
             ${img600(cdn, f)} 600w`}
      sizes="(min-width: 1210px) calc((100vw - 450px) / 4), 100vw"
      className={crossfade ? "img img-crossfade" : "img"}
    />
  );

interface CategoryImageProps {
  category: CategoryFragment;
  config: Config;
}

const CategoryImage = (props: CategoryImageProps) => {
  const { config, category } = props;
  const image = category.image_files?.[0];
  if (!image) {
    return (
      <img
        src={convertImageURL(config.cdn, undefined, {
          size: "x250",
          crop: "center",
        })}
      />
    );
  }
  const image100w = convertImageURL(config.cdn, image.public_url, {
    size: "x250",
    crop: "center",
  });
  const image200w = convertImageURL(config.cdn, image.public_url, {
    size: "x220",
    crop: "center",
  });

  return (
    <LazyImage
      srcSet={`${image100w} 100w,
                       ${image200w} 200w`}
      sizes="(min-width: 1210px) 100px, 200px"
      src={convertImageURL(config.cdn, image.public_url, {
        size: "x250",
        crop: "center",
      })}
      fallback={imageFallback(image)}
      alt={image.image_alt || ""}
      title={image.image_alt || ""}
    />
  );
};

export default ({ pageContext }: { pageContext: RouteData.Category }) => {
  const env = useEnvironment();
  const { shop, currencies, categoryBriefs, config } = useSiteData();
  const { category, products } = pageContext;
  const bestSellingBundles = useBestSellingBundles();
  useEffect(() => {
    env.analytics("track", "Product List Viewed", {
      list_id: category.id,
      category: category.title,
      products: map(products, (p) => analyticsProductData(env, env.shopCurrency, defaultVariant(p))),
    });
  }, []);
  return (
    <Layout>
      <Helmet defer={false}>
        <title>{join([shop.name, ...map(categoryChain(category.id, categoryBriefs), (c) => c.title)], " | ")}</title>
      </Helmet>
      <div className="page-with-menu" key={category.id}>
        <div className="page-with-menu__content">
          <div className="breadcrumbs">
            {shop.name}
            {map(categoryChain(category.id, categoryBriefs).slice(0, -1), (c) => (
              <React.Fragment key={c.id}>
                {" / "}
                <Link to={categoryLink(c.id, categoryBriefs)}>{c.title}</Link>
              </React.Fragment>
            ))}
            {" / "}
            {category.title}
          </div>
        </div>
        <div className="page-with-menu__content page-with-menu__content--no-padding">
          <div className="category-header">
            <div className="category-header__image">
              <CategoryImage config={config} category={category} />
            </div>
            <div className="category-header__content">
              <h1 className="category-header__title">{category.title}</h1>
              <div className="category-header__description">
                <RawContent rawContent={category.raw_content} contentImageFiles={category.content_image_files} />
              </div>
            </div>
          </div>
        </div>
        <div className="page-with-menu__content">
          <div className="product-items product-items--list">
            {map(products, (product) => {
              const variant = defaultVariant(product);
              if (!variant) return null;
              const images = variantImages(variant);
              return (
                <div className="product-items__item" key={product.id}>
                  <div className="product-items__image">
                    <VariantAnchor variant={variant} category={category}>
                      <div className="img-co img-co--1to1 img-co--img-contain">
                        {images.length >= 2 ? (
                          <>
                            {pimg(config.cdn, images[1])}
                            {pimg(config.cdn, images[0], true)}
                          </>
                        ) : (
                          pimg(config.cdn, images[0])
                        )}
                        <SaveBadge variant={variant} shop={shop} currencies={currencies} />
                      </div>
                    </VariantAnchor>
                  </div>
                  <div className="product-items__title">
                    <VariantAnchor className="default-blue-link" variant={variant} category={category}>
                      {product.title}
                    </VariantAnchor>
                  </div>
                  <div className="product-items__price">
                    <VariantPrice shop={shop} variant={variant} currencies={currencies} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="page-with-menu__content">
          <BestSellingBundles {...bestSellingBundles} />
        </div>
        <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
          <CategoryHighlight />
          <FooterMenu />
          <Footer />
        </div>
      </div>
    </Layout>
  );
};
