import React from "react";
import { LazyImage } from "components/LazyImage";
import { Link } from "gatsby";
import { categoryLink } from "utils/categoryLink";
import { getCustomField } from "utils/getCustomFields";
import { getCustomFieldCategory } from "utils/getCustomFieldCategory";
import { useSiteData } from "utils/useSiteData";

export const CategoryHighlight = () => {
  const { categoryHighlight, categoryBriefs } = useSiteData();
  if (!categoryHighlight) return null;
  const hl = getCustomField<ContentModels.vature_category_highlight>(categoryHighlight, "vature_category_highlight");
  if (!hl) return null;
  const category = getCustomFieldCategory(hl.linked_category, categoryHighlight.custom_field_objects);
  if (!category) return null;
  return (
    <div className="page-banner">
      <div className="page-banner__content">
        <div className="page-banner__header banner-header">{hl.title}</div>
        <div className="page-banner__description banner-description">{hl.description}</div>
        <Link className="page-banner__button banner-button" to={categoryLink(category.id, categoryBriefs)}>
          {hl.button_text}
        </Link>
      </div>
      <div className="page-banner__image">
        <div className="img-co img-co--banner-image">
          <LazyImage
            className="img"
            src={require("images/photo.jpg").default}
            fallback={require("images/photo.jpg.fallback.json")}
          />
        </div>
      </div>
    </div>
  );
};
