import React from "react";
import map from "lodash/map";
import { CategoryFragment, ProductFragment } from "@trunkery/internal/lib/vature-gen/types";
import { FlippingImages } from "components/FlippingImages";
import { SaveBadge } from "components/SaveBadge";
import { VariantAnchor } from "components/VariantAnchor";
import { VariantPrice } from "components/VariantPrice";
import { defaultVariant } from "@lana-commerce/core/defaultVariant";
import { useSiteData } from "utils/useSiteData";
import { variantImages } from "@lana-commerce/core/variantImages";

import { T } from "./BestSellingBundles.tlocale";

interface BestSellingBundlesProps {
  category: CategoryFragment | undefined;
  products: ProductFragment[];
}

export const BestSellingBundles = (props: BestSellingBundlesProps) => {
  const category = props.category;
  if (!category) return null;
  const { shop, currencies } = useSiteData();
  return (
    <>
      <div className="page-with-menu__header">{T("Popular Sets & Kits")}</div>
      <div className="product-items product-items--large">
        {map(props.products, (product, idx) => {
          const variant = defaultVariant(product);
          if (!variant) return null;
          const images = variantImages(variant);
          return (
            <div className="product-items__item" key={idx}>
              <div className="product-items__image">
                <VariantAnchor variant={variant} category={category}>
                  <div className="img-co img-co--525to350 img-co--img-contain">
                    <FlippingImages images={images} width={525} height={350} />
                    <SaveBadge variant={variant} shop={shop} currencies={currencies} />
                  </div>
                </VariantAnchor>
              </div>
              <div className="product-items__title">
                <VariantAnchor className="default-blue-link" variant={variant} category={category}>
                  {product.title}
                </VariantAnchor>
              </div>
              <div className="product-items__price">
                <VariantPrice shop={shop} variant={variant} currencies={currencies} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
